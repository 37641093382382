import Web3 from 'web3'

export const convertDate = (date: Date) => {
    const year = date.getUTCFullYear()
    const month = date.toLocaleString('en-US', { month: 'long' })
    const day = date.getUTCDate()
    const hours = date.getHours()
    const minutes = date.getMinutes()

    return `${day} ${month} ${year}, ${hours < 10 ? '0' + hours : hours}:${
        minutes < 10 ? '0' + minutes : minutes
    } UTC-0`
}

export interface timeLeftTypes {
    days: number
    hours: number
    minutes: number
    seconds: number
}

export const getAuctionTimeLeft = (date: Date) => {
    let timeLeft: timeLeftTypes = { days: 0, hours: 0, minutes: 0, seconds: 0 }
    const auctionEnd = new Date(date)
    const currentDate = new Date()

    // HOT FIX
    const timeLeftDay = (+auctionEnd - +currentDate) / 1000 / 60 / 60 / 24

    if (timeLeftDay < 0) {
        return timeLeft
    }

    const hoursLeft = (timeLeftDay % 1) * 24
    const minutesLeft = (hoursLeft % 1) * 60
    const secondsLeft = (minutesLeft % 1) * 60

    timeLeft.days = Math.floor(timeLeftDay)
    timeLeft.hours = Math.floor(hoursLeft)
    timeLeft.minutes = Math.floor(minutesLeft)
    timeLeft.seconds = Math.floor(secondsLeft)

    return timeLeft
}

export const cutAccountAddress = (address: string) => {
    let _address = address ? address : '0x0000000000000000000000000000000000000000'
    _address = _address.substring(0, 6) + '...' + _address.substring(_address.length - 4, _address.length)
    return _address
}

export const convertETH = (value: string) => {
    // HOT FIX
    let ether = '0'
    try {
        ether = Web3.utils.fromWei(value, 'ether')
        return parseFloat(ether)
    } catch (error) {
        return 0
    }
}

export const getCloudinaryImg = (url: string, width: number, height: number) => {
    if (url) {
        const params = `upload/w_${width},h_${height},c_crop`
        const cloudUrl = url.replace('upload', params)
        return cloudUrl
    } else return ''
}

export const getPageFromUrl = (path: string) => {
    let res = path.split('?page=')
    let page: any[] = []
    if (res && res[1]) {
        page = res[1].split('&')
        return +page
    }
    return 1
}
